<template>
  <div class="sale-wrapper bj">
    <Filtersearch
      ref="Filtersearchref"
      :list="Filtersearchlist"
      :fromdata="from1"
      @Refresh="Refresh"
      @search="search"
    >
      <template v-slot:right>
        <el-button
          @click="onHandleExport"
          type="warning"
          icon="el-icon-upload2"
          :disabled="disabledExport"
          >导出</el-button
        >
      </template>
    </Filtersearch>

    <div class="auto-table-flex">
      <tablecolumn
        ref="tablecolumnref"
        :loadingchange="loading"
        :tableDatalist="tableData"
        :tablecolumn="tablecolumn"
        @tablechange="tablechange"
      >
        <template v-slot:fullnameslot>
          <el-table-column
            prop="fullname"
            align="center"
            label="商品主图"
            fixed="left"
            :min-width="columnwidth('fullname')"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div>
                <img
                  :src="scope.row.kv"
                  alt=""
                  class="goods-image"
                  @click="onHandelDetails(scope.row)"
                />
              </div>
              <div>{{ scope.row.kv_last_time_str || "" }}</div>
              <div>{{ scope.row.srp_video_last_time_str || "" }}</div>
            </template>
          </el-table-column>
        </template>
        <template v-slot:titleslot>
          <el-table-column
          prop="title"
          align="center"
          label="商品名称"
          fixed="left"
          show-overflow-tooltip
          :min-width="columnwidth('title')"
        >
          <template slot-scope="scope">
            <span  @click="onHandelDetails(scope.row)"> {{
              `${$empty.empty(scope.row.title)}(${$empty.empty(
                scope.row.sku_code
              )})`
            }}</span>
           
          </template>
        </el-table-column>
        </template>
        <template v-slot:buy_way_txtslot>
          <el-table-column prop="buy_way_txt"  :min-width="columnwidth('buy_way_txt')" show-overflow-tooltip align="center" label="类型">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.buy_way_txt) }}
          </template>
        </el-table-column>
        </template>
        <template v-slot:gradeslot>
          <el-table-column prop="grade"  :min-width="columnwidth('grade')" show-overflow-tooltip align="center" label="等级">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.grade) }}级
          </template>
        </el-table-column>
        </template>
        <template v-slot:gross_weightslot>
          <el-table-column prop="gross_weight" :min-width="columnwidth('gross_weight')"  show-overflow-tooltip align="center" label="毛重">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.gross_weight) }}斤
          </template>
        </el-table-column>
        </template>
        <template v-slot:gross_weight_priceslot>
          <el-table-column prop="gross_weight_price" :min-width="columnwidth('gross_weight_price')"  show-overflow-tooltip align="center" label="毛单价">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.gross_weight_price) }}元/斤
          </template>
        </el-table-column>
        </template>
        <template v-slot:weightslot>
          <el-table-column prop="weight" :min-width="columnwidth('weight')"  show-overflow-tooltip align="center" label="净重">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.weight) }}斤
          </template>
        </el-table-column>
        </template>
        <template v-slot:weight_priceslot>
          <el-table-column prop="weight_price" :min-width="columnwidth('weight_price')"  show-overflow-tooltip align="center" label="净单价">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.weight_price) }}元/斤
          </template>
        </el-table-column>
        </template>
        <template v-slot:priceslot>
          <el-table-column prop="price"  :min-width="columnwidth('price')" show-overflow-tooltip align="center" label="开市价">
          <template slot-scope="scope">
            {{ tool.toDecimal2((scope.row.price * 10000) / 100 / 10000) }}元
          </template>
        </el-table-column>
        </template>
        <template v-slot:supplierslot>
          <el-table-column prop="supplier" :min-width="columnwidth('supplier')"  show-overflow-tooltip align="center" label="供货商">
          <template slot-scope="scope">
            <span v-if="scope.row.supplier">{{
              $empty.empty(scope.row.supplier.title)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        </template>
        <template v-slot:buyerslot>
          <el-table-column prop="buyer" :min-width="columnwidth('buyer')"  show-overflow-tooltip align="center" label="业务员">
          <template slot-scope="scope">
            <span v-if="scope.row.buyer">{{
              $empty.empty(scope.row.buyer.fullname)
            }}</span>
            <span v-else>—</span>
          </template>
        </el-table-column>
        </template>
        <template v-slot:category_tagslot>
          <el-table-column prop="category_tag" :min-width="columnwidth('category_tag')"  show-overflow-tooltip align="center" label="三级品类">
          <template slot-scope="scope">
            {{ (scope.row.category_tag && scope.row.category_tag.name) || "-" }}
          </template>
        </el-table-column>
        </template>
        <template v-slot:dslot>
          <el-table-column
          label="操作"
          fixed="right"
          align="center"
          min-width="130"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="storageHandle(scope.row)"
              >查看发售仓</el-button
            >
            <el-button type="text" @click="onHandleQrCode(scope.row)"
              >小程序二维码</el-button
            >
            <el-button type="text" @click="editData(scope.row)">编辑</el-button>
            <el-button type="text" v-if="is_take_off" @click="below(scope.row)">下架</el-button>
            <el-button type="text" @click="onHandleDetails(scope.row)"
              >查看变更记录</el-button
            >
          </template>
        </el-table-column>
        </template>
      </tablecolumn>
    </div>
    <!-- 发售仓 -->
    <div class="enterprise personindex">
      <el-dialog
        :center="true"
        @close="tableVisible = false"
        :visible="tableVisible"
        :title="'查看发售仓(' + listtotal + ')'"
        width="70%"
      >
        <div style="display: flex; margin-bottom: 20px">
          <div class="inputs">
            <el-input
              v-model="tdc_name"
              placeholder="请输入城市仓名称"
              clearable
            ></el-input>
          </div>
          <el-button
            @click="tdc_namesearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
        <div class="row-box d-flex flex-column" style="height: 90%">
          <el-table
            :data="listData"
            height="100%"
            v-loading="loading"
            :border="true"
            :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
          >
            <el-table-column
              type="index"
              width="100px"
              align="center"
              label="序号"
            >
            </el-table-column>
            <el-table-column prop="name" align="center" label="城市仓">
              <template slot-scope="scope">
                {{ $empty.empty(scope.row.name) }}
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <div class="pagination-position">
            <el-pagination
              type="primary"
              background
              @size-change="handleListChange"
              @current-change="handleListCurrentChange"
              :current-page="currentlistPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="salePageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="listtotal"
            >
            </el-pagination>
          </div>
        </div>
        <template #footer>
          <el-button @click="tableVisible = false">返回</el-button>
        </template>
      </el-dialog>
    </div>
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="50"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 当前列数据生成的二维码 start -->
    <qr-code-dialog ref="refQrCode"></qr-code-dialog>
    <!-- 当前列数据生成的二维码 end -->

    <!-- 商品变更记录，弹窗 start -->
    <goods-change-dialog ref="refGoodsChangeDialog"></goods-change-dialog>
    <!-- 商品变更记录，弹窗 end -->
    <!-- 商品编辑start -->
    <Salegoodseditdialog
      ref="Salegoodseditdialog"
      @tablechange="tablechange"
    ></Salegoodseditdialog>
    <!-- 商品编辑end -->
    <GoodsDetails ref="GoodsDetailsref"></GoodsDetails>
    <belowdialog ref="belowdialogref" @successbelow="successbelow"></belowdialog>
    <!-- <div v-if="detailsVisible" class="dialogbj">
      <div style="text-align: right">
        <i class="el-icon-circle-close" @click="detailsVisible = false"></i>
      </div>

      <goods-details
        :loading="detailsLoading"
        :details="goodsDetail"
      ></goods-details>
    </div> -->
  </div>
</template>
<script>
import { BASE } from "@/api";
import { IS_HOT_SALES, GOODS_STATUS } from "./utils/enum/index.js";
import url from "url";
import GoodsChangeDialog from "./modules/goods-change-dialog/index.vue";
import Salegoodseditdialog from "./modules/Salegoods-edit-dialog/index.vue";
import belowdialog from "./modules/below-dialog/index.vue";
import QrCodeDialog from "@/components/common/QrCodeDialog/index.vue";
import { MEMBER_QR_CODE_CONFIG, MINI_APP_PR_CODE_PAGE } from "@/utils/enum";
import { getMiniAppQrCode } from "@/api/export";
import { downLoadFlowBlob } from "@/utils/tools/base";
import { postProductListExport } from "@/api/export/center.js";
// import GoodsDetails from "./modules/goods-change-dialog/modules/goods-details.vue";
import GoodsDetails from "@/components/GlobalDetails.vue";
import Filtersearch from "@/components/Filtersearch.vue";
import tablecolumn from "@/components/table-column.vue";
import { color } from "echarts";
export default {
  name: "supplier",
  components: {
    QrCodeDialog,
    GoodsChangeDialog,
    GoodsDetails,
    Salegoodseditdialog,
    Filtersearch,
    tablecolumn,
    belowdialog
  },
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      IS_HOT_SALES,
      hotSalesList: Object.values(IS_HOT_SALES), // 是否爆品
      qrCodeUrl: "", // 当前需生成二维码的链接
      detailsVisible: false,
      detailsLoading: false,
      goodsDetail: {}, // 商品详情
      delivery_time: [],
      video_time: [], // 视频最后更新时间
      online_time: [], // 最后上架时间
      offline_time: [], // 最后下架时间
      kv_time: [], //主图最后更新时间
      Filtersearchlist: [
        {
          type: "select",
          name: "类型",
          clearable: true,
          model: "buy_way",
          placeholder: "请选择类型",
          label: "label",
          value: "value",
          selectoption: [
            {
              label: "全部",
              value: "",
            },
            {
              label: "地采",
              value: "1",
            },
            {
              label: "基采",
              value: "2",
            },
          ],
        },
        {
          type: "select",
          name: "标签",
          clearable: true,
          model: "promotion_tag_id",
          placeholder: "请选择标签",
          selectoptionname: "promotion_tag_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "input",
          name: "产地",
          clearable: true,
          model: "origin_name",
          placeholder: "请输入产地",
        },
        {
          type: "select",
          name: "等级",
          clearable: true,
          model: "grade",
          placeholder: "请选择等级",
          label: "label",
          value: "value",
          selectoption: [
            {
              label: "全部",
              value: "",
            },
            {
              label: "A",
              value: "A",
            },
            {
              label: "B",
              value: "B",
            },
            {
              label: "C",
              value: "C",
            },
          ],
        },
        {
          type: "input",
          name: "商品名称",
          clearable: true,
          model: "product_name",
          placeholder: "请输入商品名称",
        },
        {
          type: "input",
          name: "货号",
          clearable: true,
          model: "sku_code",
          placeholder: "请输入货号",
        },
        {
          type: "input",
          name: "供应商名称",
          clearable: true,
          model: "supplier_name",
          placeholder: "请输入供应商名称",
        },
        {
          type: "input",
          name: "业务员名称",
          clearable: true,
          model: "buyer_name",
          placeholder: "请输入业务员名称",
        },
        {
          type: "daterange",
          name: "视频更新",
          msg: "请输入视频最后一次更新的日期",
          clearable: true,
          model: "video_time",
          startmodel: "video_start_time",
          endmodel: "video_end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
        {
          type: "daterange",
          name: "主图更新",
          msg: "请输入主图最后一次更新的日期",
          clearable: true,
          model: "kv_time",
          startmodel: "kv_start_time",
          endmodel: "kv_end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
        {
          type: "select",
          name: "集配中心",
          clearable: true,
          model: "logistic_business_id",
          placeholder: "请选择集配中心",
          selectoptionname: "business_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "一级品类",
          clearable: true,
          filterable: true,
          model: "first_cid",
          placeholder: "请选择一级品类",
          selectoptionname: "first_category_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "二级品类",
          clearable: true,
          filterable: true,
          model: "cid",
          placeholder: "请选择二级品类",
          selectoptionname: "second_category_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "select",
          name: "三级品类",
          clearable: true,
          filterable: true,
          model: "category_tag_id",
          placeholder: "请选择三级品类",
          selectoptionname: "category_tag_list",
          label: "name",
          value: "id",
          selectoption: [],
        },
        {
          type: "daterange",
          name: "创建日期",
          msg: "",
          clearable: true,
          model: "delivery_time",
          startmodel: "start_time",
          endmodel: "end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
        {
          type: "input",
          name: "批量搜索",
          clearable: true,
          msg: "货号批量搜索,中间英文逗号隔开",
          model: "sku_list",
          placeholder: "请输入货号",
        },
        {
          type: "daterange",
          name: "最后上架日期",
          clearable: true,
          model: "online_time",
          startmodel: "online_start_time",
          endmodel: "online_end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
        {
          type: "daterange",
          name: "最后下架日期",
          clearable: true,
          model: "offline_time",
          startmodel: "offline_start_time",
          endmodel: "offline_end_time",
          startplaceholder: "开始时间",
          endplaceholder: "结束时间",
        },
      ],
      tablecolumn: [
      {
          prop: "title",
          width: "100px",
          label: "商品名称",
          slot: "titleslot",
          fixed: "left",
        },
        {
          prop: "fullname",
          width: "100px",
          label: "商品主图",
          slot: "fullnameslot",
          fixed: "left",
        },
       
        {
          prop: "buy_way_txt",
          label: "类型",
          slot: "buy_way_txtslot",
        },
        {
          prop: "grade",
          label: "等级",
          slot: "gradeslot",
        },
        {
          prop: "gross_weight",
          label: "毛重",
          slot: "gross_weightslot",
        },
        {
          prop: "gross_weight_price",
          label: "毛单价",
          slot: "gross_weight_priceslot",
        },
        {
          prop: "weight",
          label: "净重",
          slot: "weightslot",
        },
        {
          prop: "weight_price",
          label: "净单价",
          slot: "weight_priceslot",
        },
        {
          prop: "price",
          label: "开市价",
          slot: "priceslot",
        },
        {
          prop: "supplier",
          label: "供货商",
          slot: "supplierslot",
        },
        {
          prop: "buyer",
          label: "业务员",
          slot: "buyerslot",
        },
        {
          prop: "create_time",
          label: "创建时间",
     
        },
        {
          prop: "stock",
          label: "库存数量",
     
        },
        {
          prop: "first_cid_name",
          label: "一级品类",
     
        },
        {
          prop: "cid_name",
          label: "二级品类",
     
        },
        {
          prop: "category_tag",
          label: "三级品类",
          slot: "category_tagslot",
        },
        {
          prop: "service_charges",
          label: "第三方服务费",
     
        },
        {
          prop: "origin_name",
          label: "产地",
     
        },
        {
          prop: "spec",
          label: "规格",
     
        },
        {
          prop: "supply_time",
          label: "供应商提交审核时间",
     
        },
        {
          prop: "online_type_text",
          label: "发布类型",
     
        },
        {
          prop: "last_online_time",
          label: "最后上架时间",
     
        },
        {
          prop: "last_offline_time",
          label: "最后下架时间",
     
        },
        {
          prop: "promotion_tag",
          label: "标签",
     
        },
        {
          prop: "intro_20",
          label: "简介",
     
        },
        {
          prop: "total_score",
          label: "得分",
     
        },
        {
          prop: "name",
          label: "操作",
          slot: "dslot",
          fixed: "left",
          width:'130'
        },
        
      ],

      from: {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //业务名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //业务id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        buy_way: "",
        // hot_sales: "",
        state: GOODS_STATUS.success.value, // 上架
        page: 1,
        pageSize: 50,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
        offline_time: [],
        online_time: [],
        delivery_time: [],
        kv_time: [],
        video_time: [],
      },
      from1: {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //业务名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //业务id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        buy_way: "",
        // hot_sales: "",
        state: GOODS_STATUS.success.value, // 上架
        page: 1,
        pageSize: 50,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
        offline_time: [],
        online_time: [],
        delivery_time: [],
        kv_time: [],
        video_time: [],
      },
      salePageSize: 20,
      saleid: "",
      tableData: [],
      listData: [],
      tableVisible: false,
      tdc_name: "",
      options: [
        {
          id: "A",
        },
        {
          id: "B",
        },
        {
          id: "C",
        },
      ],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        business_type_code: "logistics", //集配：logistics 自提点：delivery
        store_id: "",
        itemdata: "",
        name: "",
        username: "",
        password: "",
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        business_type_code: [
          { required: true, message: "请选择业务模块", trigger: "change" },
        ],
        itemdata: [
          { required: true, message: "请选择业务名称", trigger: "change" },
        ],
      },
      options1: [],
      list: [],
      list1: [],
      currentlistPage: 1,
      listtotal: 0,
      searchdata: "",
      disabledExport: false, // 导出按钮
      is_take_off:false
    };
  },
  created() {
    this.hqlist();
    // this.getBusinessList();
    //   this.seletlist();
    this.getsearchdata();
  },
  watch: {
    "ruleForm.business_type_code": {
      handler(newVal, oldVal) {
        if (newVal == "logistics") {
          this.options1 = this.list;
        } else {
          this.options1 = this.list1;
        }
        this.ruleForm.store_id = "";
        this.ruleForm.name = "";
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    columnwidth(e){
      let width=''
      this.tablecolumn.forEach(el=>{
        if(el.prop==e){
          width=el.width?el.width:''
        }
      })
      return width
    },
    tablechange(e){
this.tablecolumn=e;
    },
    tablechange() {
      this.hqlist();
    },
    getsearchdata() {
      this.$api.general.searchdata().then((res) => {
        console.log(res);
        this.searchdata = res.data;
        this.Filtersearchlist.forEach((el) => {
          if (el.type == "select" && el.selectoptionname) {
            el.selectoption = [
              { id: "", name: "全部" },
              ...res.data[el.selectoptionname],
            ];
          }
        });
      });
    },
    timechange(val, name) {
      if (val) {
        this.from.start_time = val[0];
        this.from.end_time = val[1];
        this.from1.start_time = val[0];
        this.from1.end_time = val[1];
      } else {
        this.from.start_time = "";
        this.from.end_time = "";
        this.from1.start_time = "";
        this.from1.end_time = "";
      }
    },
    addTimeChange(val, name) {
      console.log(val, name, "name===");
      if (name == "online_time") {
        if (val) {
          this.from.online_start_time = val[0];
          this.from.online_end_time = val[1];
          this.from1.online_start_time = val[0];
          this.from1.online_end_time = val[1];
        } else {
          this.from.online_start_time = "";
          this.from.online_end_time = "";
          this.from1.online_start_time = "";
          this.from1.online_end_time = "";
        }
      } else if (name == "video_time") {
        if (val) {
          this.from.video_start_time = val[0];
          this.from.video_end_time = val[1];
          this.from1.video_start_time = val[0];
          this.from1.video_end_time = val[1];
        } else {
          this.from.video_start_time = "";
          this.from.video_end_time = "";
          this.from1.video_start_time = "";
          this.from1.video_end_time = "";
        }
      } else if (name == "kv_time") {
        if (val) {
          this.from.kv_start_time = val[0];
          this.from.kv_end_time = val[1];
          this.from1.kv_start_time = val[0];
          this.from1.kv_end_time = val[1];
        } else {
          this.from.kv_start_time = "";
          this.from.kv_end_time = "";
          this.from1.kv_start_time = "";
          this.from1.kv_end_time = "";
        }
      } else if (name == "offline_time") {
        if (val) {
          this.from.offline_start_time = val[0];
          this.from.offline_end_time = val[1];
          this.from1.offline_start_time = val[0];
          this.from1.offline_end_time = val[1];
        } else {
          this.from.offline_start_time = "";
          this.from.offline_end_time = "";
          this.from1.offline_start_time = "";
          this.from1.offline_end_time = "";
        }
      }
    },
    /**
     * 展示详情
     */
    onHandelDetails(row) {
      // this.detailsVisible = true;
      // this.detailsLoading = true;
      this.goodsDetail = {
        ...row,
        price: this.tool.toDecimal2((row.price * 10000) / 100 / 10000),
      };
      // // 模拟loading
      // setTimeout(() => {
      //   this.detailsLoading = false;
      // }, 500);
      this.$refs.GoodsDetailsref.hqlist(this.goodsDetail);
    },
    //编辑
    editData(e) {
      console.log(e, "11111111111111");

      this.$refs.Salegoodseditdialog.onInitData(e);
    },
    //下架
    successbelow(){
      this.hqlist();
    },
    below(row){
this.$refs.belowdialogref.onInitData(row)
    },
    /**
     * 查看变更记录
     */
    onHandleDetails(row) {
      const params = {
        skuCode: row.sku_code,
      };
      this.$refs.refGoodsChangeDialog.onInitData(params);
    },
    getBusinessList() {
      this.$api.general.businessList().then((res) => {
        this.options = res.data;
      });
    },
    search(e) {
      console.log(this.from1.sku_code, "sku===");
      if (e.sku_code && e.sku_code.length < 4) {
        this.tool.message("货号输入至少4位", "error");
        return;
      }
      this.from.page = 1;
      this.currentPage = 1;
      this.from = e;
      this.hqlist();
    },
    /**
     * 导出逻辑
     */
    async onHandleExport() {
      this.disabledExport = true;
      try {
        const query = {
          ...this.from,
          token: sessionStorage.getItem("token"),
        };
        delete query.page;
        delete query.pageSize;
        await postProductListExport(query);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postPurchaseSettleExport err", err);
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    storageHandle(data) {
      console.log(data);
      this.currentlistPage = 1;
      this.saleid = data.id;
      this.saleLogisticsList(data.id);
      this.tableVisible = true;
    },
    handleListChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.salePageSize = val;
      this.currentlistPage = 1;
      this.saleLogisticsList(this.saleid);
    },
    handleListCurrentChange(val) {
      this.currentlistPage = val;
      this.saleLogisticsList(this.saleid);
    },
    tdc_namesearch() {
      this.currentlistPage = 1;
      this.saleLogisticsList(this.saleid);
    },
    saleLogisticsList(id) {
      this.$api.general
        .saleLogisticsList({
          tdc_name: this.tdc_name,
          product_id: id,
          page: this.currentlistPage,
          pageSize: this.salePageSize,
        })
        .then((res) => {
          this.listData = res.data.data;
          this.listtotal = res.data.total;
        });
    },
    Refresh() {
      this.from = {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //业务名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //业务id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        buy_way: "",
        // hot_sales: "",
        state: GOODS_STATUS.success.value, // 上架
        page: 1,
        pageSize: 50,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
        offline_time: [],
        online_time: [],
        delivery_time: [],
        kv_time: [],
        video_time: [],
      };
      this.from1 = {
        is_tdc_owner: "", // 仓主专区
        logistic_business_id: "", //集配中心id
        promotion_tag_id: "", //促销标签id
        category_tag_id: "", // 品种/品牌id
        grade: "", //等级 A、B、C
        product_name: "", //商品名称
        sku_code: "", //商品编码
        supplier_name: "", // 供应商名称
        buyer_name: "", //业务名称
        sku_list: "", // 批量货号搜索
        supplier_id: "", //供应商id
        buyer_id: "", //业务id
        start_time: "", //开始时间
        end_time: "", //结束时间
        first_cid: "", //一级分类id
        cid: "", //二级分类id
        buy_way: "",
        // hot_sales: "",
        state: GOODS_STATUS.success.value, // 上架
        page: 1,
        pageSize: 50,
        origin_name: "", // 产地
        online_start_time: "", // 最后一次上架时间起
        online_end_time: "", // 最后一次上架时间止
        video_start_time: "", // 视频最后更新时间起
        video_end_time: "", // 视频最后更新时间止
        kv_start_time: "", // 主图最后更新时间起
        kv_end_time: "", // 主图最后更新时间止
        offline_start_time: "", //最后一次下架时间起
        offline_end_time: "", //最后一次下架时间止
        offline_time: [],
        online_time: [],
        delivery_time: [],
        kv_time: [],
        video_time: [],
      };
      this.currentPage = 1;
      this.delivery_time = [];
      this.kv_time = [];
      this.video_time = [];
      this.online_time = [];
      this.offline_time = [];
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    hqlist() {
      this.loading = true;
      this.$api.general.productList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.is_take_off = res.data.is_take_off;
        this.loading = false;
      });
    },
    /**
     * 展示二维码
     */
    async onHandleQrCode(row) {
      const { sku_code, title: name } = row;
      const { sku } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      const params = {
        env_version: sku.envCode,
        type: MINI_APP_PR_CODE_PAGE.sku.value,
        sku_code,
      };
      try {
        const res = await getMiniAppQrCode(params);
        downLoadFlowBlob(res, res?.filename || name);
      } catch (err) {
        console.log("ajax getMiniAppQrCode err", err);
      }
      /** 前端生成二维码逻辑，已弃 */
      // const { id, sku_code, title: name, price } = row;
      // const { sku } = MEMBER_QR_CODE_CONFIG[process.env.NODE_ENV];
      // const url =
      //   sku?.value &&
      //   `${sku.value}?id=${id}&price=${price}&sku_code=${sku_code}`;
      // const url = sku?.value && `${sku.value}?sku_code=${sku_code}`;
      // const params = {
      //   url,
      //   name,
      // };
      // this.$refs.refQrCode.onInitData(params);
      // console.log("🆒 onHandleQrCode", row, params);
    },
  },
};
</script>
<style lang="scss">
.sale-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;

  .goods-image {
    width: 50px;
    height: 50px;
  }

  .title1 {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;

    .seach {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      // justify-content: flex-end;
      gap: 10px;
      .inputs {
        // width: 207px;
        margin-right: 8px;
      }
    }

    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }

  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }

  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;

    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }

    .imgs {
      display: flex;

      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;

        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }

        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }

    .disply {
      display: flex;
      margin-bottom: 22px;
    }

    .mr {
      margin-right: 31px;
    }

    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }

      .el-input {
        width: 302px;
      }
    }
  }
}

.colors {
  color: #fa884c;
}

.times {
  margin-right: 8px;
}

// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
