var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sale-wrapper bj" },
    [
      _c("Filtersearch", {
        ref: "Filtersearchref",
        attrs: { list: _vm.Filtersearchlist, fromdata: _vm.from1 },
        on: { Refresh: _vm.Refresh, search: _vm.search },
        scopedSlots: _vm._u([
          {
            key: "right",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: "warning",
                      icon: "el-icon-upload2",
                      disabled: _vm.disabledExport,
                    },
                    on: { click: _vm.onHandleExport },
                  },
                  [_vm._v("导出")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        { staticClass: "auto-table-flex" },
        [
          _c("tablecolumn", {
            ref: "tablecolumnref",
            attrs: {
              loadingchange: _vm.loading,
              tableDatalist: _vm.tableData,
              tablecolumn: _vm.tablecolumn,
            },
            on: { tablechange: _vm.tablechange },
            scopedSlots: _vm._u([
              {
                key: "fullnameslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "fullname",
                        align: "center",
                        label: "商品主图",
                        fixed: "left",
                        "min-width": _vm.columnwidth("fullname"),
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _c("img", {
                                  staticClass: "goods-image",
                                  attrs: { src: scope.row.kv, alt: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandelDetails(scope.row)
                                    },
                                  },
                                }),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(scope.row.kv_last_time_str || "")
                                ),
                              ]),
                              _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.srp_video_last_time_str || ""
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "titleslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "title",
                        align: "center",
                        label: "商品名称",
                        fixed: "left",
                        "show-overflow-tooltip": "",
                        "min-width": _vm.columnwidth("title"),
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandelDetails(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        `${_vm.$empty.empty(
                                          scope.row.title
                                        )}(${_vm.$empty.empty(
                                          scope.row.sku_code
                                        )})`
                                      )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "buy_way_txtslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "buy_way_txt",
                        "min-width": _vm.columnwidth("buy_way_txt"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "类型",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.buy_way_txt)
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "gradeslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "grade",
                        "min-width": _vm.columnwidth("grade"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "等级",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$empty.empty(scope.row.grade)) +
                                  "级 "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "gross_weightslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "gross_weight",
                        "min-width": _vm.columnwidth("gross_weight"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "毛重",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.gross_weight)
                                  ) +
                                  "斤 "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "gross_weight_priceslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "gross_weight_price",
                        "min-width": _vm.columnwidth("gross_weight_price"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "毛单价",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(
                                      scope.row.gross_weight_price
                                    )
                                  ) +
                                  "元/斤 "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "weightslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "weight",
                        "min-width": _vm.columnwidth("weight"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "净重",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$empty.empty(scope.row.weight)) +
                                  "斤 "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "weight_priceslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "weight_price",
                        "min-width": _vm.columnwidth("weight_price"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "净单价",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row.weight_price)
                                  ) +
                                  "元/斤 "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "priceslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "price",
                        "min-width": _vm.columnwidth("price"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "开市价",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      (scope.row.price * 10000) / 100 / 10000
                                    )
                                  ) +
                                  "元 "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "supplierslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "supplier",
                        "min-width": _vm.columnwidth("supplier"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "供货商",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.supplier
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$empty.empty(
                                          scope.row.supplier.title
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "buyerslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "buyer",
                        "min-width": _vm.columnwidth("buyer"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "业务员",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.buyer
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$empty.empty(
                                          scope.row.buyer.fullname
                                        )
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "category_tagslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        prop: "category_tag",
                        "min-width": _vm.columnwidth("category_tag"),
                        "show-overflow-tooltip": "",
                        align: "center",
                        label: "三级品类",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    (scope.row.category_tag &&
                                      scope.row.category_tag.name) ||
                                      "-"
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
              {
                key: "dslot",
                fn: function () {
                  return [
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        fixed: "right",
                        align: "center",
                        "min-width": "130",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.storageHandle(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看发售仓")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandleQrCode(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("小程序二维码")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.editData(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm.is_take_off
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.below(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("下架")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onHandleDetails(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看变更记录")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "enterprise personindex" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                center: true,
                visible: _vm.tableVisible,
                title: "查看发售仓(" + _vm.listtotal + ")",
                width: "70%",
              },
              on: {
                close: function ($event) {
                  _vm.tableVisible = false
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.tableVisible = false
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex", "margin-bottom": "20px" } },
                [
                  _c(
                    "div",
                    { staticClass: "inputs" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入城市仓名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.tdc_name,
                          callback: function ($$v) {
                            _vm.tdc_name = $$v
                          },
                          expression: "tdc_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.tdc_namesearch },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "row-box d-flex flex-column",
                  staticStyle: { height: "90%" },
                },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: {
                        data: _vm.listData,
                        height: "100%",
                        border: true,
                        "header-cell-style": {
                          color: "#333333",
                          background: "#EFF6FF",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "100px",
                          align: "center",
                          label: "序号",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          align: "center",
                          label: "城市仓",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$empty.empty(scope.row.name)) +
                                    " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pagination-position" },
                    [
                      _c("el-pagination", {
                        attrs: {
                          type: "primary",
                          background: "",
                          "current-page": _vm.currentlistPage,
                          "page-sizes": [10, 20, 50, 100],
                          "page-size": _vm.salePageSize,
                          layout: "total, sizes, prev, pager, next, jumper",
                          total: _vm.listtotal,
                        },
                        on: {
                          "size-change": _vm.handleListChange,
                          "current-change": _vm.handleListCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.currentPage,
              "page-sizes": [10, 20, 50, 100],
              "page-size": 50,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("qr-code-dialog", { ref: "refQrCode" }),
      _c("goods-change-dialog", { ref: "refGoodsChangeDialog" }),
      _c("Salegoodseditdialog", {
        ref: "Salegoodseditdialog",
        on: { tablechange: _vm.tablechange },
      }),
      _c("GoodsDetails", { ref: "GoodsDetailsref" }),
      _c("belowdialog", {
        ref: "belowdialogref",
        on: { successbelow: _vm.successbelow },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }