var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "below-dialog",
      attrs: {
        title: "下架商品",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c("div", { staticClass: "title_box1" }, [
            _vm._v("是否确认下架商品："),
          ]),
          _c("div", { staticClass: "title_box2" }, [
            _vm._v(
              "【" +
                _vm._s(_vm.itemobj.weight) +
                "斤】" +
                _vm._s(
                  `${_vm.$empty.empty(_vm.itemobj.title)}(${_vm.$empty.empty(
                    _vm.itemobj.sku_code
                  )})`
                )
            ),
          ]),
          _c("div", { staticClass: "title_box3" }, [
            _vm._v("业务：" + _vm._s(_vm.itemobj.buyer.fullname)),
          ]),
          _c(
            "el-form",
            {
              ref: "refForm",
              attrs: { model: _vm.formData, rules: _vm.formRules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "下架原因：", prop: "remark" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入下架原因",
                      type: "textarea",
                      rows: 2,
                    },
                    model: {
                      value: _vm.formData.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "remark", $$v)
                      },
                      expression: "formData.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.onHandleSubmit } },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }