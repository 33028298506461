<template>
  <el-dialog
    title="下架商品"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="below-dialog"
    center
  >
    <div class="contents">
      <div class="title_box1">是否确认下架商品：</div>
      <div class="title_box2">【{{itemobj.weight}}斤】{{  `${$empty.empty(itemobj.title)}(${$empty.empty(
                itemobj.sku_code
              )})`}}</div>
      <div class="title_box3">业务：{{itemobj.buyer.fullname}}</div>

      <el-form :model="formData" ref="refForm" :rules="formRules">
        <el-form-item label="下架原因：" prop="remark">
          <el-input
            v-model="formData.remark"
            placeholder="输入下架原因"
            type="textarea"
            :rows="2"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit">保存</el-button>
    </span>
  </el-dialog>
</template>
  
  <script>
export default {
  name: "below-dialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      formData: {
        remark: "", // 供应商ID
        sku_code: "", // 是否显示店铺
      }, // 表单数据
      itemobj: "",
      formRules: {
        remark: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入下架原因",
          },
        ],
      },
    };
  },
  methods: {
    /**
     * 初始化
     */
    onInitData(data) {
      this.itemobj = JSON.parse(JSON.stringify(data));
      this.formData.sku_code = data.sku_code;
      this.dialogVisible = true;
      this.$nextTick(()=>{
        this.$refs.refForm.resetFields();
      })
    },

    /**
     * 关闭操作
     */
    onHandleClose() {
      this.$refs.refForm?.clearValidate();
      this.dialogVisible = false;
      this.formData.remark = '';
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          this.$api.general.productstatetakeoff(this.formData).then(res=>{
            this.$refs.refForm?.clearValidate();
            this.dialogVisible = false;
            this.formData.remark = '';
            
            this.$message({
          type: "success",
          message: "下架成功",
        });
this.$emit('successbelow',true)
          })
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.below-dialog {
  .contents {
    padding-top: 0px;
    padding-bottom: 20px;
    .title_box1{
        font-weight: bold;
        color: #000;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .title_box2{
      font-size: 16px;
        color: #000;
        margin-bottom: 10px;
    }
    .title_box3{
      font-size: 16px;
        color: #000;
        margin-bottom: 10px;
    }
  }
  /deep/ .el-form {
    // margin-left: 120px;
 
  }
  .el-input {
    width: 300px;
  }
}
</style>
  